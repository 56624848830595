import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import sha256 from 'crypto-js/sha256';
import CheckoutForm from './CheckoutForm';
import amplitude from 'amplitude-js';
import moment from 'moment/moment';
import styles from "./Payment.module.scss";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = '6325c393bf71ea040fa29781d0fb76263e59faf5';

const testKey = 'pk_test_51IQytoJwa78Nc1Fj4yxM9tCXN6KDwzHcUv8uPpTSjK2R3BU1FxGlt10NbZRVyEsJULrLgXfDo2xp93kKckNixPxU00T0qPsZmh'
const liveKey = 'pk_live_51IQytoJwa78Nc1FjgA5V2BohKo1lacqu7RsI0EoGl5XMcf4BexV34obBDCkwUF8qQtD3L298Bkc0XoLyPv8rbt5n00PLj9RnLf'

import React from 'react';
import WaitingRegistrationPopup from "../../components/UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import strings from "../../assets/localization/strings.jsx";
import LazyImage from "../../components/UI/lazyImage/LazyImage";

const Payment = (props) => {
    const { data, partner, domain, checkout} = props;
    const themeData = data.themeData
    const liveMode = data.liveMode
    const offer = data.settings.paywall.offer
    const specialOffer = data.specialOffer

    const [stripePromise, setStripePromise] = useState(null);
    const [currentPeriod, setCurrentPeriod] = useState(JSON.parse(localStorage.getItem('period')));
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)
    // const [activePaymentMethod, setActivePaymentMethod] = useState('cards')
    const discountStatus = localStorage.getItem('discountStatus');
    const navigate = useNavigate();
    // const [finishStatus, setfinishStatus] = useState(false);
    // const [isOpen, setOpen] = useState(false);
    let buttonText = discountStatus === 'trial' ? strings.continueTrial1 : strings.continue

    useEffect(() => {
      setDisplayWaitingRegistrationPopup(false)
      const eventId = `checkout.${getCookie('id')}`;
      ReactPixel.track('InitiateCheckout', {eventID: eventId});
      const payload = {
        data: [
          {
            event_name: "InitiateCheckout",
            event_time: moment().unix(),
            action_source: "website",
            event_id: eventId,
            user_data: {
                client_user_agent: navigator.userAgent,
                external_id: sha256(getCookie('id')).toString()
            }
          }
        ]
      };
     // const fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]).join();
      const fbc = localStorage.getItem('fbclid');
      const fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]).join();

      if(fbc) {
        payload.data[0].user_data.fbc = fbc;
      }

      if(fbp) {
        payload.data[0].user_data.fbp = fbp;
      }

      fetch(`https://graph.facebook.com/v10.0/${(domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${domain.includes('net')? netToken: token}`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
    }, [])

    function getCookie(name) {
      const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    useEffect(() => {
        setDisplayWaitingRegistrationPopup(false)
        setStripePromise(loadStripe(liveMode ? liveKey : testKey));
        amplitude.getInstance().logEvent('screen_onboarding_payment_appeared');
    }, []);

    const closePayment = () => {
    // campaign !== 'shret022470'
        if(partner !== 'k' && (offer !== null && offer !== undefined)) {
            if(specialOffer === 1){
            localStorage.setItem('discountStatus', offer);
            } else if(specialOffer === 2){
             if(localStorage.getItem('discountStatus') === null || localStorage.getItem('discountStatus') === undefined){
             localStorage.setItem('discountStatus', offer);
             } else if(localStorage.getItem('discountStatus') === 'discount' && data.pricesVersion === "default"){
               localStorage.setItem('discountStatus', "trial");
             }
            }
        }
        navigate('/paywall');
        amplitude.getInstance().logEvent('button_onboarding_payment_close_tapped');
    }

    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <>
            <div className={mainContainerClass}>

                { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }

                { checkout === "2" || data.settings.payment.checkout ?
                    (
                        <>
                            <div className={styles.header}>
                                <LazyImage
                                    className={styles.headerClose}
                                    src={themeData.paymentCloseButton.src}
                                    srcWebp={themeData.paymentCloseButton.srcWebp}
                                    alt=""
                                    onClick={closePayment}
                                />
                                <h1 className={styles.headerCheckout}>{strings.payment.checkout}</h1>
                            </div>
                            <div className={`${styles.paymentInfoContainer} flex-between`}>
                                <div className={styles.oldPriceRow}>
                                    <p className={styles.oldPrice}>{strings.payment.personalized} </p>
                                    <p className={styles.oldPriceValue}> ${currentPeriod?.paymentInfo.oldPrice} </p>
                                </div>
                                <div className={styles.offerRow}>
                                    <p className={styles.discountPrice}>{currentPeriod?.paymentInfo.priceDiscountPercentage}% {strings.payment.offer}</p>
                                    <p className={styles.discountPriceValue}>- ${currentPeriod?.paymentInfo.priceDiscount}</p>
                                </div>
                                { discountStatus === 'trial' &&
                                    (<div className={styles.offerRow}>
                                        <p className={styles.discountPrice}>7-days trial</p>
                                        <p className={styles.oldPriceValue}> $1</p>
                                    </div>)
                                }
                                <hr className={styles.hr}></hr>
                                <div className={styles.totalRow}>
                                    <p className={styles.totalPrice}>{strings.payment.total}</p>
                                    <div className={styles.totalPriceCell}>
                                        <div className={styles.totalPriceColumn}>
                                            {
                                                discountStatus === 'trial' ?
                                                    (<p className={styles.totalPriceValue}>$1</p>) :
                                                    (<p className={styles.totalPriceValue}>${currentPeriod?.total}</p>)
                                            }
                                            <p className={styles.totalPriceValueSave}>{strings.payment.youSave} ${currentPeriod?.paymentInfo.priceDiscount} ({currentPeriod?.paymentInfo.priceDiscountPercentage}% off)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <LazyImage
                                className={styles.closeIcon}
                                src={themeData.paymentCloseButton.src}
                                srcWebp={themeData.paymentCloseButton.srcWebp}
                                alt=""
                                onClick={closePayment}
                            />
                            <h1 className={styles.title}>{strings.payment.title}</h1>
                            { discountStatus !== 'trial' && (currentPeriod?.hasTrial === null || currentPeriod?.hasTrial === undefined)?
                                (<p className={styles.subtitle}>{strings.payment.start}<b>{strings.paywall.products[currentPeriod?.title]}</b> <br/>${currentPeriod?.newPerDay} {strings.payment.perDay}</p>) :
                                (<p className={styles.subtitle}>{strings.payment.start}<b>{(currentPeriod?.trialDays !== null && currentPeriod?.trialDays !== undefined && currentPeriod?.trialDays === 3) ? strings.payment.trial3 : strings.payment.trial}</b>{strings.payment.justFor} $1 <br/>{strings.payment.then}${currentPeriod?.total}/{strings.paywall.periods[currentPeriod?.paymentInfo.period]}</p>)
                            }
                        </>
                    )
                }


                {/*<div className={`${styles.paymentContainer} flex-between`}>*/}
                {/*  <div className={`${styles.paymentMethod} ${activePaymentMethod === 'applepay' ? styles.active : ''} ${styles.applePay}`} onClick={() => selectPaymentMethod('applepay')}>*/}
                {/*    <img className={styles.applePay} src="/images/applePay-icon.png" alt="" />*/}
                {/*    <p>Pay</p>*/}
                {/*  </div>*/}
                {/*  <div className={`${styles.paymentMethod} ${activePaymentMethod === 'cards' ? styles.active : ''}`} onClick={() => selectPaymentMethod('cards')}>*/}
                {/*    <div className={styles.cardsContainer}>*/}
                {/*      <p>Credit card</p>*/}
                {/*      <img className={styles.cards} src="/images/cardsIcon.png" alt="" />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className={`${styles.paymentMethod} ${activePaymentMethod === 'paypal' ? styles.active : ''}`} onClick={() => selectPaymentMethod('paypal')}>*/}
                {/*    <img className={styles.payPal} src="/images/PayPal-icon.png" alt="" />*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className={styles.underline}></div>*/}
                {/*{*/}
                {/*  currentPeriod?.paymentInfo.title !== '7-day' ? (*/}
                {/*    <>*/}
                {/*      <div className={`${styles.countContainer} flex-between`}>*/}
                {/*        <p>{currentPeriod?.paymentInfo.title} subscription</p>*/}
                {/*        <p>{currentPeriod?.paymentInfo.oldPrice} USD</p>*/}
                {/*      </div>*/}
                {/*      <div className={`${styles.countContainer} flex-between`}>*/}
                {/*        <p>{currentPeriod?.paymentInfo.priceDiscountPercentage} price discount</p>*/}
                {/*        <p>-{currentPeriod?.paymentInfo.priceDiscount} USD</p>*/}
                {/*      </div>*/}
                {/*      <div className={styles.underline}></div>*/}
                {/*    </>*/}
                {/*  ) : null*/}
                {/*}*/}
                {/*<div className={`${styles.totalContainer} flex-between`}>*/}
                {/*  <h2>Total:</h2>*/}
                {/*  <p><span>{currentPeriod?.total} USD</span>{currentPeriod?.paymentInfo.period}</p>*/}
                {/*</div>*/}
                {/*<span className={styles.description}>You agree that {currentPeriod?.total} USD will be charged and automatically billed every month. If you don’t want to be billed, you may cancel your subscription via support everdance.supp@gmail.com</span>*/}


                {stripePromise && (
                    <Elements stripe={stripePromise}>
                        <CheckoutForm
                            period={currentPeriod}
                            buttonText={buttonText}
                            setWaitingPopup={setDisplayWaitingRegistrationPopup}
                            liveMode={liveMode}
                            domain={domain}
                            pageInfo={data.settings.payment}
                            themeData={themeData}
                        />
                    </Elements>
                )}
            </div>
        </>
    );
}

export default Payment;